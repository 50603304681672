<template lang="pug">
include ../../../configs/template
div.container-fluid
  div.row.mt-3.text-left
    div.col-12.col-sm-12.col-md-6
      +data-info('dateEffective', 'getDateFormat(coefficientById.date_start)')
    div(v-if="coefficientById.date_end").col-12.col-sm-12.col-md-6
      +data-info('dateEnd', 'getDateFormat(coefficientById.date_end)')
    div.col-12.col-sm-12.col-md-6
      +data-info('etiPercent', 'coefficientById.percent_of_eti')
    div.col-12.col-sm-12.col-md-6
      +data-info('profitPercent', 'coefficientById.percent_of_profit')
</template>

<script>
import { getStatus, getDateFormat } from '@/mixins/main'
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'InfoCoefficient',
  props: {
    coefficientById: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      getDateFormat,
      getStatus
    }
  },
  computed: {
    ...mapGetters(['getDirectoryObject']),
    ...mapState({
      namelang: state => state.main.lang === 'en' ? 'name_ukr' : 'name_eng'
    })
  }
}
</script>
